@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

* {
    font-family: 'Roboto', sans-serif;
    /* color: #234361; */
}

:focus {
    outline: 0;
}

.filepond--credits {
    display: none;
}

.fc-event-time, .fc-event-title {
    padding: 0 1px;
    white-space: normal;
}


.unconfirmed-game-event {
    opacity: 0.75;
    color: #BF7022;
}

.unconfirmed-game-event:hover {
    opacity: 0.75;
    color: #BF7022;
}

.unconfirmed-game-event.not-my-game {
    opacity: 0.3 !important;
}

.confirmed-game-event {
    color: #399367;
}

.confirmed-game-event:hover {
    color: #399367;
}

.rejected-game-event {
    color: #EC4C47;
    text-decoration: line-through !important;
}

.rejected-game-event:hover {
    color: #EC4C47;
    text-decoration: line-through !important;
}

.cancelled-game-event {
    color: #66788A;
    opacity: 0.5;
    text-decoration: line-through !important;
}

.cancelled-game-event:hover {
    color: #66788A;
    opacity: 0.5;
    text-decoration: line-through !important;
}
